import React, { useEffect, useState } from 'react'
import { WEBSITE_NAME, EMAIL, EMAIL_HREF, PHONE, PHONE_HREF } from '../../config';


export const Cookie = () => {


    return (
        <>
            <section class="main-privacy">
                <div class="container">
                    <h4 class="sub-heading">Overview</h4>
                    <p>We use data collection files on our website called “Cookies” that help us in analyzing the website flow,
                        and are necessary for the functionality of the website. Without cookies, certain areas of the website
                        will not function, making cookies essential for the complete operations of the website. Moreover,
                        cookies help us in remembering the users’ information and interests, allowing us to make the website
                        usage more efficient and display relevant content to the users.
                    </p>
                    <p>Cookies policy is for the visitors of our website, it explains what cookies are and how are they used.
                        Our visitors should read the policy in order to have an understanding of the types of cookies we use,
                        why we use them and how they help us in providing a better browsing experience to our users.</p>
                    <h4 class="sub-heading">Cookies</h4>
                    <p>Cookies are small text file placed on the hard drive of the users’ devices including computer, tablet,
                        laptop and mobile phones when they visit our website. Cookies are positioned to make the users’ browsing
                        experience better by providing them personalized and relevant content.</p>
                    <h4 class="sub-heading">Types of Cookies</h4>
                    <p>Session cookies and Persistent cookies are the two main types of cookies. <strong>Persistent
                        Cookies</strong> remain on the users devices for a longer period of time or until manually removed
                        by the user. These cookies are activated upon every visit to the website. Whereas, <strong>Session
                            Cookies</strong> are temporary and are only saved while the users stay on the website. These cookies
                        are deleted as soon as the browser window is closed and the particular session ends. Cookies have the
                        following four functions and can be categorized as below:</p>
                    <ul class="addons-list sty4">
                        <li> <strong>Strictly Necessary Cookies</strong> are essential for our website to perform basic
                            operations. Without these cookies various parts of the website will not be accessible, leading to
                            the user not being able to experience the complete functionality of the website.</li>
                        <li><strong>Performance Cookies</strong> are used to collect statistical data of the visitor’s usage of
                            the website. These cookies do not contain personal information and are only used for improving the
                            users’ browsing experience on the website.</li>
                        <li><strong>Functional Cookies</strong> allows the website to remember the users’ preferences while
                            their visit on the website. These preferences are stored and help in providing a personalized
                            experience to the users, upon their return to the website.</li>
                        <li><strong>Targeting Cookies</strong> are used to collect information related to the visitors’
                            interests and display more relevant content in order to enhance their browsing experience. Without
                            the use of these cookies we will not be able to advertise content of the users’ interest to them.
                        </li>
                    </ul>
                    <h4 class="sub-heading">Purpose of Placing Cookies</h4>
                    <p>We use cookies for various purposes. Some cookies are essential for operational and technical purposes
                        where as others enable us to provide a personalized experience for visitors.</p>
                    <ul class="addons-list sty3">
                        <li>To speed up users’ future experience on the website by storing information and using it upon their
                            return.</li>
                        <li>To give users a unique browsing experience by tracking their interests and displaying relevant
                            information.</li>
                        <li>To analysis page flow usage of our website, allowing us to monitor and improve our performance.</li>
                        <li>To maintain the security and integrity and allow users to browse secure areas of the website.</li>
                    </ul>
                    <h4 class="sub-heading">Managing and Deleting Cookies</h4>
                    <p>Users can manage or opt out of having cookies by changing the setting in the Manage Cookies Tab on their
                        browser. Managing or disallowing cookies is dependent upon the type of browser being used. By
                        disallowing or blocking cookies users’ may be unable to access certain areas of the website, therefore,
                        it is not recommended in order to experience the website in its complete functional condition.</p>

                </div>
            </section>
        </>

    )
}

