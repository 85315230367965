import React, { useState } from 'react'
import { animtedLogo, iconic_logo, tabsbg, textcircle_01, threeDLogo } from '../../assest/images'



let dataArray = [
  {
    id: 0,
    heading: "Iconic Logo",
    description: `Iconic logos are known for their simplicity. They are simple
    elements used in the manner to represent a story.`,
    img: iconic_logo,
  },
  {
    id: 1,
    heading: "3d Logo",
    description: `A 3D logo design gives a logo the detail and feeling that a
    simple logo fails to give.`,
    img: threeDLogo,
  },
  {
    id: 2,
    heading: "Animated Logo",
    description: `Animated logos are the key to grabbing the attention of your
    audience.`,
    img: animtedLogo,
  },
  {
    id: 3,
    heading: "Illustrative Logo",
    description: `Illustrative logos comprise of pictures depicting a range of
    meaning.`,
    img: animtedLogo,
  },
]

export const OurServices = () => {


  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(0);
  };
  return (
    <div>
      <section className="our-service-sec">
        <div className="container">
          <div className="secHeading main-hdg">
            <h2>
              Our{" "}
              <span>
                Services{" "}
                <img
                  alt=""
                  className="lazyload"
                  src={textcircle_01}

                />
              </span>
            </h2>
          </div>

          {/* <div className="tp">
      <div className="tp__list anim-1 on">

        
        <div className="tp__list__left">
          <div
            className="scrollmagic-pin-spacer"
            data-scrollmagic-pin-spacer=""
          >
            <div className="pinned-image">
              <a className="tp__list__left__img" href="javascript:;">
                
                <div className="tp__list__left__wrapper">
                  <img alt="iconic_logo" src={iconic_logo} />
                </div>
              </a>
            </div>
          </div>
          <img
            className="tabs-bg-img lazyload"
            src={tabsbg}
          />
        </div>

        
        <div className="tp__list__right">
              {dataArray.map(value =>{
                return(
                  <div
               
                  key={value.id}
          className={`tp__list__right__item ${value.id === activeIndex ? '_is-active' : ''}`}
                  data-chars="severstal"
                  data-href="javascript:;"
                  src={iconic_logo}
                  onMouseEnter={() => handleMouseEnter(value.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="tp__list__right__item__header anim-overflow">
                    <span className="list-anim">{value.heading}</span>
                    <div className="list-anim-second">{value.heading}</div>
                  </div>
                  <div className="tp__list__right__item__desc anim-overflow">
                    <span className="list-anim">
                     {value.description}
                    </span>
                  </div>
                  <div className="tp__list__right__item__img">
                    <a href="javascript:;">
                      <img
                        alt=""
                        className="lazyload"
                        src={value.img}
                      />
                      <div className="tp__list__right__item__img__text">
                        Watch project
                      </div>
                    </a>
                  </div>
                </div>
                )
              })}

        
        </div>



      </div>
    </div> */}



          <div className="tp">
            <div className="tp__list anim-1 on">
              <div className="tp__list__left">
                <div className="scrollmagic-pin-spacer" data-scrollmagic-pin-spacer="">
                  <div className="pinned-image">
                    <a className="tp__list__left__img" href="javascript:;">
                      <div className="tp__list__left__wrapper">
                        {<img alt="iconic_logo" src={dataArray[activeIndex].img} />}

                      </div>
                    </a>
                  </div>
                </div>
                <img className="tabs-bg-img lazyload" src={tabsbg} />
              </div>

              <div className="tp__list__right">
                {dataArray.map((value) => (
                  <div
                    key={value.id}
                    className={`tp__list__right__item ${value.id === activeIndex ? '_is-active' : ''}`}
                    data-chars="severstal"
                    data-href="javascript:;"
                    onMouseEnter={() => handleMouseEnter(value.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="tp__list__right__item__header anim-overflow">
                      <span className="list-anim">{value.heading}</span>
                      <div className="list-anim-second">{value.heading}</div>
                    </div>
                    <div className="tp__list__right__item__desc anim-overflow">
                      <span className="list-anim">{value.description}</span>
                    </div>
                    <div className="tp__list__right__item__img">
                      <a href="javascript:;">
                        <img alt="" className="lazyload" src={value.img} />
                        <div className="tp__list__right__item__img__text">Watch project</div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

