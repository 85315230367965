const Admin_Panel='info@logoaura.com';
const PHONE= '+61 489 951 988';
const PHONE_HREF= 'tel:61489951988';
const PHONE_TWO= '+61 489 951 988';
const PHONE_TWO_HREF= 'tel:61489951988';
const EMAIL= 'info@logoaura.com';
const EMAIL_HREF= 'mailto:info@logoaura.com';
const CONTACT_EMAIL ='info@logoaura.com';
const CONTACT_EMAIL_HREF = 'mailto:info@logoaura.com';
const ADDRESS= '88 Sutter St, San Francisco, CA 94104';
const WEBSITE_NAME= 'Logo Aura';
const SITE_CURRENCY_SYMBOLS ='$';
const WEBSITE_LOGO= 'https://www.logoaura.com/logo.png';

export{
    Admin_Panel,
    PHONE,
    PHONE_HREF,
    PHONE_TWO,
    PHONE_TWO_HREF,
    EMAIL,
    EMAIL_HREF,
    CONTACT_EMAIL,
    CONTACT_EMAIL_HREF,
    ADDRESS,
    WEBSITE_NAME,
    SITE_CURRENCY_SYMBOLS,
    WEBSITE_LOGO,
}