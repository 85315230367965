import React from "react";
import { qoitebg, test1, test2, test3, testimonalsText } from '../../assest/images';
import Slider from "react-slick";
import { WEBSITE_NAME } from "../../config";

export const HappyClient = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <section className="happy-clients lazy-background">
        <div className="container">
          <div className="col-md-10 testi-content-rpr">
            <div className="testi-content ">
              <h1>
                Words from{" "}
                <span>
                  {" "}
                  Happy
                  <img className="happy-text lazyload" src={testimonalsText} />
                </span>{" "}
                Clients
              </h1>
              <img className="qouit-bg lazyload" src={qoitebg} />
              <div className="main-testi client_logos_slides carosal_section">
                <Slider {...settings} className="slider_happy_clients">
                  <div className="item" >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img className="lazyload " src={test1} />
                    </div>
                    <p>
                      “{WEBSITE_NAME} I needed a logo and they provided great service over and
                      beyond. They made sure everything was what I wanted and
                      more. The customer service is great, and they followed up
                      with calls, emails and text messages.”
                    </p>
                    <h4>Cheryl Tawana</h4>
                  </div>

                  <div className="item">
                    {/* <img className="lazyload" src={test2} /> */}
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img className="lazyload " src={test2} />
                    </div>
                    <p>
                      “{WEBSITE_NAME} helped my customers
                      recognize the genuineness and authenticity of my brand.
                      Thanks to Logo Aura and its team for
                      their true dedication and hard work.”
                    </p>
                    <h4>David Guzman</h4>
                  </div>
                  <div className="item">
                    {/* <img className="lazyload" src={test3} /> */}
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img className="lazyload " src={test3} />
                    </div>
                    <p>
                      “{WEBSITE_NAME} Team was brilliant from
                      start to finish, extremely professional, knowledgeable and
                      responsive. Highly recommend! thank you for creating my
                      new logo, ”
                    </p>
                    <h4>Jonathan Yang</h4>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
