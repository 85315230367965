import React, { createContext, useEffect, useContext } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const toggleChat = () => {
    if (window.$zopim) {
      window.$zopim.livechat.window.show();
   }
  };
  // Function to toggle the LiveChat widget
     useEffect(() => {

      const chatButtons = document.querySelectorAll('.chat'); // Select all elements with the class 'chatt'

      chatButtons.forEach(chatButton => {
         chatButton.addEventListener('click', toggleChat);
      });

      // Cleanup: Remove event listeners when the component unmounts
      return () => {
         chatButtons.forEach(chatButton => {
            chatButton.removeEventListener('click', toggleChat);
         });
      };
   }, []);

  return (
    <ChatContext.Provider value={{ toggleChat }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
