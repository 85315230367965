import React, { useState } from 'react'
import { packageImg, packagecombo1 } from '../../assest/images';
// import { ModalSection } from '../../layout/modalSection';
import Slider from "react-slick";
import { useModal } from "../../ModalContext";

export const LogoInfinite = () => {
  const { openModal } = useModal();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <section className="budget pad-sec" style={{ paddingBottom: "80px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="top-packages ">
                <div className="combo-slider">
                  <Slider {...settings} className='slider_section'>
                    <div className="item">
                      <div className="pricing_bx packagebox">
                        <div className="row align-items-center">
                          <div className="col-md-6 break_col ">
                            <h4 className="mn-h1">
                              <span className="pack-title">Logo Infinite</span>
                              Crafting your brand to completion!
                            </h4>
                            <div className="combo-list-style">
                              <ul className="">
                                <li> Unlimited Original Logo Concepts</li>
                                <li> 8 Dedicated Logo Designer (Industry Specific)</li>
                                <li> Unlimited Revisions</li>
                                <li> 3 Page Basic Website</li>
                                <li>
                                  {" "}
                                  Stationery Design (Business Card, Letterhead,
                                  Envelope)
                                </li>
                                <li> Brand Book</li>
                                <li> 500 Business Cards </li>
                                <li> 500 Letterheads</li>
                                <li> Email Signature Design</li>
                                <li>
                                  {" "}
                                  Social Media Designs (Facebook, Twitter, Instagram)
                                </li>
                                <li> 2 Sided Flyer OR Bi-Fold Brochure Design</li>
                                <li> With Grey Scale Format</li>
                                <li> Free Icon Design</li>
                                <li> Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li> 24 - 48 Hours Turn Around Time</li>
                                <li className="features"> MORE FEATURES</li>
                                <li> 100% Satisfaction</li>
                                <li> 100% Ownership Rights</li>
                                <li> Money Back Guarantee</li>
                                <li> Dedicated Account Manager</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 break_col packagebox">
                            <figure>
                              <img
                                src={packagecombo1}
                                className="lazyload"
                                alt=""
                              />
                              <h4 className="pac-price big-price">$799</h4>
                            </figure>
                            <div className="final">
                              <a
                                href="javascript:;"
                                onClick={openModal}
                                className="hvr-bounce-to-bottom btn-default openpopup order-package"
                              >
                                Order Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="pricing_bx packagebox">
                        <div className="row align-items-center">
                          <div className="col-md-6 break_col">
                            <h4 className="mn-h1">
                              <span className="pack-title">Logo Combo</span>
                              Crafting your brand to completion!
                            </h4>
                            <div className="combo-list-style">
                              <ul>
                                <li>Unlimited Original Logo Concepts </li>
                                <li>12 Dedicated Logo Designer (Industry Specific) </li>
                                <li>Unlimited Revisions </li>
                                <li>7 Page Informative Website </li>
                                <li>
                                  Stationery Design (Business Card, Letterhead,
                                  Envelope){" "}
                                </li>
                                <li>Brand Book</li>
                                <li>500 Business Cards</li>
                                <li>500 Letterheads</li>
                                <li>Email Signature Design</li>
                                <li>
                                  Social Media Designs (Facebook, Twitter, Instagram)
                                </li>
                                <li>2 Sided Flyer OR Bi-Fold Brochure Design</li>
                                <li>With Grey Scale Format </li>
                                <li>Free Icon Design </li>
                                <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li>24 - 48 Hours Turn Around Time</li>
                                <li>
                                  <span>MORE FEATURES</span>
                                </li>
                                <li>100% Satisfaction </li>
                                <li>100% Ownership Rights </li>
                                <li>Money Back Guarantee </li>
                                <li>Dedicated Account Manager </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 break_col packagebox">
                            <figure>
                              <img
                                // data-src="assets/images/packagecombo2.png"
                                src={packageImg}
                                className="lazyload"
                                alt=""
                              />
                              <h4 className="pac-price big-price">$999</h4>
                            </figure>
                            <div className="final">
                              <a
                                href="javascript:;"
                                onClick={openModal}
                                className="hvr-bounce-to-bottom btn-default openpopup order-package"
                              >
                                Order Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

